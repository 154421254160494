<template>
  <RadioGroup v-model="selected">
    <RadioGroupLabel class="sr-only">
      Pricing plans
    </RadioGroupLabel>

    <div class="relative bg-white rounded-md -space-y-px">
      <RadioGroupOption
        as="template"
        v-for="(plan, planIdx) in plans"
        :key="plan.name"
        :value="plan"
        v-slot="{ checked, active }"
      >
        <div :class="[planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-primary-100 border-primary-200 z-10' : 'border-gray-200', 'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none']">
          <div class="flex items-center text-sm">
            <span :class="[checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-primary' : '', 'h-4 w-4 rounded-full border flex items-center justify-center']" aria-hidden="true">
              <span class="rounded-full bg-white w-1.5 h-1.5" />
            </span>

            <RadioGroupLabel as="span" class="ml-3 font-medium text-gray-900">{{ plan.label }}</RadioGroupLabel>
          </div>

          <RadioGroupDescription class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
            <span :class="[checked ? 'text-primary-600' : 'text-gray-400', 'font-normal']">1 hardline drop (main uplink) dedicated bandwidth</span>
          </RadioGroupDescription>

          <RadioGroupDescription :class="[checked ? 'text-primary-700' : 'text-gray-500', 'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right']">{{ plan.limit }}</RadioGroupDescription>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>

  <div
    v-show="selected.bandwidth === 'custom'"
    class="grid grid-cols-6 gap-6 mb-2"
  >
    <div class="col-span-2 sm:col-span-2">
      <label for="company_website" class="mt-3 block text-sm font-medium text-gray-700">
        Required Dedicated Bandwidth
      </label>

      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          v-model="customBandwidth"
          type="number"
          min="100"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-16 shadow-sm sm:text-sm border-gray-300 rounded-md"
          @blur="clampCustomBandwidth"
        />

        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm" id="price-currency">
            MBps
          </span>
        </div>
      </div>
    </div>
  </div>

  <input
    v-model="bandwidth"
    id="order_dedicated_bandwidth"
    name="order[dedicated_bandwidth]"
    type="hidden"
  >

  <div
    class="grid grid-cols-6 gap-6"
  >
    <div class="col-span-2 sm:col-span-2">
      <label for="company_website" class="mt-3 block text-sm font-medium text-gray-700">
        Number of in-booth connections/in-booth cabling
      </label>

      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          v-model="drops"
          id="order_hardline_drops"
          name="order[hardline_drops]"
          type="number"
          min="1"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          @blur="clampDrops"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from '@headlessui/vue';

const plans = [{
  bandwidth: 10,
  label: '10MBps',
}, {
  bandwidth: 20,
  label: '20MBps',
}, {
  bandwidth: 30,
  label: '30MBps',
}, {
  bandwidth: 40,
  label: '40MBps',
}, {
  bandwidth: 50,
  label: '50MBps',
}, {
  bandwidth: 100,
  label: '100MBps',
}, {
  bandwidth: 'custom',
  label: 'more than 100MBps',
}];

export default {
  name: 'BandwidthSelector',

  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },

  setup() {
    const store = useStore();
    const selected = ref(plans[0]);
    const bandwidth = ref(10);
    const customBandwidth = ref(101);
    const drops = ref(1);

    watch(selected, (newValue) => {
      if (newValue.bandwidth === 'custom') {
        bandwidth.value = customBandwidth.value;
      } else {
        bandwidth.value = newValue.bandwidth;
      }
    });

    watch(customBandwidth, (newValue) => {
      bandwidth.value = newValue;
    });

    watch(drops, (newValue) => {
      store.commit('setHardlineDrops', newValue);
    });

    const clampCustomBandwidth = (event) => {
      const field = event.target;
      const value = parseInt(field.value, 10);

      if (Number.isNaN(value) || value < 101) {
        field.value = 101; // eslint-disable-line no-param-reassign
        bandwidth.value = 101;
      }
    };

    const clampDrops = (event) => {
      const field = event.target;
      const value = parseInt(field.value, 10);

      if (Number.isNaN(value) || value < 1) {
        field.value = 1; // eslint-disable-line no-param-reassign
        store.commit('setHardlineDrops', 1);
      }
    };

    return {
      bandwidth,
      clampCustomBandwidth,
      clampDrops,
      customBandwidth,
      drops,
      plans,
      selected,
    };
  },
};
</script>
