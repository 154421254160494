<template>
  <img
    :src="src"
    :alt="`${email}'s gravatar'`"
  />
</template>

<script>
import md5 from 'md5';

export default {
  name: 'GravatarImage',

  props: {
    default: {
      type: String,
      default: 'identicon',
    },

    email: {
      type: String,
      default: '',
    },

    rating: {
      type: String,
      default: 'g',
    },
  },

  computed: {
    src() {
      const hash = md5(this.email.toLowerCase().trim());
      return `${this.baseUrl}/${hash}?d=${this.default}&r=${this.rating}`;
    },
  },

  data() {
    return {
      baseUrl: 'https://www.gravatar.com/avatar',
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
