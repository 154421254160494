import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      order: {
        client: {
          id: null,
          name: '',
        },
        event: {
          id: null,
          title: '',
        },
        booth_number: '',
        dedicated_bandwidth: 10,
        hardline_drops: 1,
        wifi: false,
      },
    };
  },

  actions: {},

  getters: {},

  mutations: {
    setClient(state, client) {
      state.order.client = {
        id: client.id,
        name: client.name,
      };
    },

    setDedicatedBandwidth(state, bandwidth) {
      state.order.dedicated_bandwidth = bandwidth;
    },

    setEvent(state, event) {
      state.order.event = {
        id: event.id,
        title: event.title,
      };
    },

    setHardlineDrops(state, drops) {
      state.order.hardline_drops = drops;
    },

    setWifi(state, wifi) {
      state.order.wifi = wifi;
    },
  },
});

export default store;
