// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { createApp } from 'vue/dist/vue.esm-bundler';
import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import ApplicationNavbar from '@components/application/Navbar';
import ApplicationStore from '@stores/application';

import '@/notifications';
import '@/orders/new';
import '@stylesheets/application.scss';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener('turbolinks:load', () => {
  const navbarEl = document.getElementById('navbar');

  if (navbarEl) {
    const { pageTitle } = navbarEl.dataset;

    ApplicationStore.commit('setCurrentUser', JSON.parse(window.currentUser));

    createApp({
      components: { ApplicationNavbar },
      template: `<ApplicationNavbar page-title="${pageTitle}" />`,
    })
      .use(ApplicationStore)
      .mount(navbarEl);
  }
});
