class Notifications {
  mount() {
    this._showNotifications();
  }

  _showNotifications() {
    document.querySelectorAll('.notification').forEach((notification) => {
      const closeButton = notification.querySelector('.js-close-notification');

      notification.classList.replace('hidden', 'flex');
      notification.classList.add('animate__fadeIn');

      setTimeout(() => {
        notification.classList.replace('animate__fadeIn', 'animate__fadeOut');

        notification.addEventListener('animationend', () => {
          notification.classList.replace('flex', 'hidden');
        });
      }, 3000);

      closeButton.addEventListener('click', () => {
        notification.addEventListener('animationend', () => {
          notification.classList.replace('flex', 'hidden');
        });

        notification.classList.replace('animate__fadeIn', 'animate__fadeOut');
      });
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  const notifications = new Notifications();
  notifications.mount();
});
