<template>
  <div class="bg-gray-800 pb-32">
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="border-b border-gray-700">
          <div class="flex items-center justify-between h-16 px-4 sm:px-0">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <img :src="logo" class="h-8" />
              </div>

              <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-4">
                  <template v-for="item in navigation" :key="item">
                    <template v-if="matchesCurrentPage(item.pathname)">
                      <a :href="item.link" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">{{ item.title }}</a>
                    </template>

                    <a v-else :href="item.link" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">{{ item.title }}</a>
                  </template>
                </div>
              </div>
            </div>

            <div class="hidden md:block">
              <profile-menu />
            </div>

            <div class="-mr-2 flex md:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open main menu</span>

                <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="border-b border-gray-700 md:hidden">
        <div class="px-2 py-3 space-y-1 sm:px-3">
          <template v-for="item in navigation" :key="item">
            <template v-if="matchesCurrentPage(item.pathname)">
              <a :href="item.link" class="bg-gray-900 text-white block px-3 py-2
                rounded-md text-base font-medium">{{ item.title }}</a>
            </template>

            <a v-else :href="item.link" class="text-gray-300 hover:bg-gray-700
              hover:text-white block px-3 py-2 rounded-md text-base font-medium">{{ item.title }}</a>
          </template>
        </div>

        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <gravatar-image
                class="h-10 w-10 rounded-full"
                :email="currentUser.email"
              />
            </div>

            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">Tom Cook</div>
              <div class="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
            </div>

            <button class="ml-auto bg-gray-800 flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div class="mt-3 px-2 space-y-1">
            <div
              v-for="item in profile"
              :key="item"
            >
              <div
                v-if="item.separator"
                class="border-t border-gray-100"
              />

              <a
                v-else
                :href="item.link"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >
                {{ item.title }}
              </a>
            </div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <header class="py-10">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-white">
          {{ pageTitle }}
        </h1>
      </div>
    </header>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline';

import GravatarImage from '@components/GravatarImage';
import ProfileMenu from '@components/application/ProfileMenu';
import TwoJaxLogo from '@images/twojax-logo.png';

const navigation = [{
  title: 'Events',
  link: '/',
  pathname: '',
}];

const profile = [{
  title: 'Your Account',
  link: '/users/edit',
}, {
  separator: true,
}, {
  title: 'Sign Out',
  link: '/users/sign_out',
}];

export default {
  props: {
    pageTitle: {
      type: String,
      default: 'Events',
    },
  },

  components: {
    BellIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    GravatarImage,
    MenuIcon,
    ProfileMenu,
    XIcon,
  },

  setup() {
    const open = ref(false);
    const logo = TwoJaxLogo;

    const matchesCurrentPage = pathname => location.pathname.match(pathname)?.length; /* eslint-disable-line no-restricted-globals */

    return {
      logo,
      matchesCurrentPage,
      navigation,
      profile,
      open,
    };
  },
};
</script>
