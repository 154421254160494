import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      currentUser: null,
    };
  },

  actions: {
    setCurrentUser({ commit }, currentUser) {
      commit('setCurrentUser', currentUser);
    },
  },

  getters: {},

  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
  },
});

export default store;
