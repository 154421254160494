import { createApp } from 'vue';

import OrdersNewContainer from '@components/orders/NewContainer';
import OrdersStore from '@stores/orders';

document.addEventListener('turbolinks:load', () => {
  if (document.body.classList.contains('events-orders-new')) {
    const newOrderContainer = document.getElementById('new-order-container');

    const bootstrap = JSON.parse(newOrderContainer.dataset.bootstrap);

    OrdersStore.commit('setClient', bootstrap.client);
    OrdersStore.commit('setEvent', bootstrap.event);

    createApp(OrdersNewContainer)
      .use(OrdersStore)
      .mount(newOrderContainer);
  }
});
