<template>
  <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1">
      <div class="px-4 sm:px-0">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Event Information</h3>
      </div>
    </div>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-2 sm:col-span-2">
              <label for="order_event_id" class="block text-sm font-medium text-gray-700">
                Event
              </label>

              <select
                id="order_event_id"
                name="order[event_id]"
                class="pointer-events-none mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm text-gray-500"
              >
                <option :value="order.event.id">{{ order.event.title }}</option>
              </select>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-6">
            <div class="col-span-2 sm:col-span-2">
              <label for="order_client_id" class="block text-sm font-medium text-gray-700">
                Client
              </label>

              <select
                id="order_client_id"
                name="order[client_id]"
                class="pointer-events-none mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm text-gray-500"
              >
                <option :value="order.client.id">{{ order.client.name }}</option>
              </select>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-6">
            <div ref="boothNumberContainer" class="col-span-1 sm:col-span-2">
              <label for="order_booth_number" class="block text-sm font-medium text-gray-700">
                Exhibiting Company

                <span class="text-red-700">*</span>
              </label>

              <input
                v-model="exhibiting_company"
                id="order_exhibiting_company"
                type="text"
                name="order[exhibiting_company]"
                class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-6">
            <div ref="boothNumberContainer" class="col-span-1 sm:col-span-1">
              <label for="order_booth_number" class="block text-sm font-medium text-gray-700">
                Booth Number

                <span class="text-red-700">*</span>
              </label>

              <input
                v-model="booth_number"
                id="order_booth_number"
                type="text"
                name="order[booth_number]"
                class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-6">
            <div ref="contactNameContainer" class="col-span-1 sm:col-span-2">
              <label for="order_contact_name" class="block text-sm font-medium text-gray-700">
                Contact Name

                <span class="text-red-700">*</span>
              </label>

              <input
                v-model="contact_name"
                id="order_contact_name"
                type="text"
                name="order[contact_name]"
                class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-6">
            <div ref="contactEmailContainer" class="col-span-1 sm:col-span-2">
              <label for="order_contact_email" class="block text-sm font-medium text-gray-700">
                Contact Email

                <span class="text-red-700">*</span>
              </label>

              <input
                v-model="contact_email"
                id="order_contact_email"
                type="email"
                name="order[contact_email]"
                class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>

  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Internet/Telecom</h3>

          <p class="mt-1 text-sm text-gray-600">
            Dedicated Internet service comes with 1 hardline drop (main uplink)
            and up to 25 DHCP IP addresses. Static configuration available on
            request. If you require more IP addresses please email
            <a
              href="mailto:orders@twojax.net"
              class="text-primary hover:text-primary-600"
            >
              orders@twojax.net
            </a>.
          </p>
        </div>
      </div>

      <div class="mt-5 md:mt-0 md:col-span-2">
        <bandwidth-selector />
        <wifi-switch class="mt-6" />
        <technician-switch class="mt-6" />
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>

  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
        </div>
      </div>

      <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="bg-yellow-50 border-r-4 border-yellow-400 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/exclamation -->
              <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>

            <div class="ml-3">
              <p class="text-sm text-yellow-700">
                After submission, a quote will be e-mailed to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 flex justify-end">
    <button
      type="submit"
      class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      :class="[ submitDisabled ? 'cursor-not-allowed opacity-20': '' ]"
      :title="[ submitDisabled ? 'Please fill out all fields before submitting the form': '' ]"
      :disabled="submitDisabled"
    >
      Submit Order
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

import BandwidthSelector from '@components/orders/BandwidthSelector';
import TechnicianSwitch from '@components/orders/TechnicianSwitch';
import WifiSwitch from '@components/orders/WifiSwitch';

export default {
  name: 'OrdersNewContainer',

  components: {
    BandwidthSelector,
    TechnicianSwitch,
    WifiSwitch,
  },

  computed: {
    ...mapState(['order']),

    submitDisabled() {
      let disabled = false;

      if (isEmpty(this.exhibiting_company.trim())) {
        disabled = true;
      }

      if (isEmpty(this.booth_number.trim())) {
        disabled = true;
      }

      if (isEmpty(this.contact_name.trim())) {
        disabled = true;
      }

      if (isEmpty(this.contact_email.trim())) {
        disabled = true;
      }

      return disabled;
    },
  },

  data() {
    return {
      booth_number: '',
      contact_email: '',
      contact_name: '',
      exhibiting_company: '',
    };
  },
};
</script>
