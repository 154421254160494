<template>
  <div class="bg-white shadow sm:rounded-lg">
    <SwitchGroup as="div" class="px-4 py-5 sm:p-6">
      <SwitchLabel as="h3" class="text-lg leading-6 font-medium text-gray-900" passive>
        Dedicated In-booth Wi-Fi
      </SwitchLabel>

      <div class="mt-2 sm:flex sm:items-start sm:justify-between">
        <div class="max-w-xl text-sm text-gray-500">
          <p id="renew-description">
            Up to 2 SSIDs available. Protected by WPA2 128-bit AES encryption.
          </p>
        </div>

        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <Switch
            v-model="enabled"
            :class="[enabled ? 'bg-primary-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </div>
      </div>
    </SwitchGroup>

    <input
      v-model="enabled"
      id="order_wifi"
      name="order[wifi]"
      type="hidden"
    >
  </div>
</template>

<script>
import { ref } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const enabled = ref(false);

    return {
      enabled,
    };
  },
};
</script>
