<template>
  <div v-if="currentUser" class="ml-4 flex items-center md:ml-6">
    <button
      v-if="hasNotifications"
      class="bg-gray-800 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
    >
      <span class="sr-only">View notifications</span>
      <BellIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <!-- Profile dropdown -->
    <Menu as="div" class="ml-3 relative">
      <div>
        <MenuButton class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span class="sr-only">Open user menu</span>
          <gravatar-image
            class="h-8 w-8 rounded-full"
            :email="currentUser.email"
          />
        </MenuButton>
      </div>

      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="p-1">
            <MenuItem v-slot="{ active }">
              <a
                href="/users/edit"
                :class="[
                  active ? 'bg-primary text-white' : '',
                  'group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:text-white'
                ]"
              >
                <user-circle-icon
                  :active="active"
                  class="w-5 h-5 mr-2 text-gray-500 group-hover:text-primary-100"
                  aria-hidden="true"
                />

                Your Account
              </a>
            </MenuItem>
          </div>

          <div class="p-1">
            <MenuItem v-slot="{ active }">
              <a
                href="/users/sign_out"
                data-method="delete"
                :class="[
                  active ? 'bg-primary text-white' : '',
                  'group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-700 hover:text-white'
                ]"
              >
                <logout-icon
                  :active="active"
                  class="w-5 h-5 mr-2 text-gray-500 group-hover:text-primary-100"
                  aria-hidden="true"
                />

                Sign Out
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>

  <div v-else class="flex items-center">
    <div class="flex-shrink-0">
      <a href="/users/sign_in" class="inline-flex items-center px-4 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        Sign in
      </a>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import {
  BellIcon,
  CreditCardIcon,
  LogoutIcon,
  UserCircleIcon,
} from '@heroicons/vue/outline';

import GravatarImage from '@components/GravatarImage';

export default {
  props: {
    hasNotifications: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BellIcon,
    CreditCardIcon,
    LogoutIcon,
    GravatarImage,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    UserCircleIcon,
  },

  setup() {
    const store = useStore();
    const currentUser = computed(() => store.state.currentUser);

    return {
      currentUser,
    };
  },
};
</script>
